import React from 'react'
import { Container } from 'react-bootstrap'

export default function Index () {

return (
<>
  <html lang='en'>
  <header></header>
    <body>
        <Container style={{textAlign :"center"}}>
            <h1 className='pt-5' >Thank you!</h1>
            <p>We will come back to you shortly.</p>
        </Container>
    </body>
    <footer>
    <br></br>
    </footer>
  </html>
</>
)
}


